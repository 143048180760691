import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Headers from '../components/Headers';

function Contact(props) {
  const link = props.linksDs.find(link => link.link === '/contact');
  const pageTitle = props.lang === 'en' ? link.name_en : link.name_se;
  return (
    <>
      <Headers title={pageTitle} lang={props.lang} clink='/contact' />
      <Breadcrumbs title={pageTitle} lang={props.lang} bgimg="https://mhs.mharis.info/wp-content/uploads/2023/04/bg-contact-banner.jpg" />
      <div>Contact</div>
    </>
  )
}

export default Contact
