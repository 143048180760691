import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumbs(props) {
  //let altText = "Your meaningful image description";
  //const backgroundImageUrl = 'https://mhs.mharis.info/wp-content/uploads/2023/04/bg-about-tms-banner.jpg';
  /* background: `url(${backgroundImageUrl}) no-repeat center center fixed`, */
  const containerStyle = {
    backgroundImage: `url(${props.bgimg})`,
  };
  return (
    <>
      <div className="bc lg:h-64 h-36 relative" style={containerStyle}>
        <div className="absolute top-0 left-0 w-full h-full bc-background-overlay z-10"></div>
        <div className="absolute w-full lg:h-64 lg:mt-28 h-36 mt-16 z-20">
          <div className="uk-container uk-container-xlarge">
            {/*breadcumbs*/}
            <div data-uk-grid="margin:0" className="flex content-center">
              <div className="uk-width-1-1">
                <ul className="uk-breadcrumb text-grne text-base">
                  <li><Link to="/" className="no-underline text-grne text-base font-medium hover:text-grne">{props.lang === 'en' ? 'Home' : 'Hem'}</Link></li>
                  <li><span className="text-grne text-base font-medium">{props.title}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="text-white text-center">
            <h1 className="text-3xl font-bold">Your Title</h1>
            <p className="text-lg">Your content goes here.</p>
          </div>
        </div>
        <div className="absolute flex items-left uk-container uk-container-xlarge bc-padding z-20">
        */}
      </div>
    </>

  )
}

export default Breadcrumbs
