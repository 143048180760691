import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-ldple text-base font-normal font-sans py-1 text-center border-t-grne border-t-2 sticky bottom-0 z-50">
      © 2001 – 2023 <Link to="https://mharis.info/" className="text-grne no-underline hover:text-grne hover:no-underline">Muhammad Haris</Link>. All rights reserved.
    </footer>
  )
}

export default Footer
