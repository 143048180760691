import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import Headers from '../components/Headers';

function Photography(props) {
  const link = props.linksDs.find(link => link.link === '/photography');
  const pageTitle = props.lang === 'en' ? link.name_en : link.name_se;
  return (
    <>
      <Headers title={pageTitle} lang={props.lang} clink='/photography'/>
      <Breadcrumbs title={pageTitle} lang={props.lang} bgimg="https://mhs.mharis.info/wp-content/uploads/2023/04/bg-about-tms-banner.jpg" />
      <div>Photography</div>
    </>
  )
}

export default Photography
