import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Photography from "./pages/Photography";
import Resume from "./pages/Resume";
import Blog from "./pages/Blog";
import Volunteer from "./pages/Volunteer";
import Testimonials from "./pages/Testimonials";
import Contact from "./pages/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  let Links =[
    { link:"/", name_en:"Home", subname_en:"Quick Overview", name_se:"Hem", subname_se:"Snabb översikt" },
    { link:"/about", name_en:"About", subname_en:"Intro & Biography", name_se:"Om oss", subname_se:"Introduktion & Biografi"  },
    { link:"/portfolio", name_en:"Portfolio", subname_en:"Professional Work", name_se:"Portfölj", subname_se:"Professionellt arbete" },
    { link:"/photography", name_en:"Photography", subname_en:"Freezing of Feelings", name_se:"Fotografi", subname_se:"Frysning av känslor" },
    { link:"/resume", name_en:"Resume", subname_en:"Skills & Education", name_se:"Meritförteckning", subname_se:"Färdigheter & Utbildning" },
    { link:"/blog", name_en:"Blog", subname_en:"Latest Posts", name_se:"Blogg", subname_se:"Senaste inlägg" },
    { link:"/volunteer", name_en:"Volunteer", subname_en:"Act of Kindness", name_se:"Volontär", subname_se:"Godhetsgärning" },
    { link:"/testimonials", name_en:"Testimonials", subname_en:"People’s Opinion", name_se:"Referenser", subname_se:"Människors åsikter" },
    { link:"/contact", name_en:"Contact", subname_en:"Be in Touch", name_se:"Kontakt", subname_se:"Håll kontakten" },
  ];
  const [lang, setLang] = useState('en');
  let Langs =[
    { lang:"english", title_en:"English", title_se:"Engelska", code:"en", icon:"fi-gb" },
    { lang:"swedish", title_en:"Swedish", title_se:"Svenska", code:"se", icon:"fi-se" },
  ];
  useEffect(() => {
    // Example: Use setLang in an effect
    setLang(lang);
    console.log('Language has changed:', lang);
  }, [lang]);
  return (
    <>
    <Router>
        <Header linksDs={Links} langsDs={Langs} lang={lang} setLang={setLang} />
        <Routes>
          <Route path="/" element={<Home linksDs={Links} lang={lang} />} />
          <Route path="/About" element={<About linksDs={Links} lang={lang} />} />
          <Route path="/Portfolio" element={<Portfolio linksDs={Links} lang={lang} />} />
          <Route path="/Photography" element={<Photography linksDs={Links} lang={lang} />} />
          <Route path="/Resume" element={<Resume linksDs={Links} lang={lang} />} />
          <Route path="/Blog" element={<Blog linksDs={Links} lang={lang} />} />
          <Route path="/Volunteer" element={<Volunteer linksDs={Links} lang={lang} />} />
          <Route path="/Testimonials" element={<Testimonials linksDs={Links} lang={lang} />} />
          <Route path="/Contact" element={<Contact linksDs={Links} lang={lang} />} />
        </Routes>
        <Footer />
      </Router>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
