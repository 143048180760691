import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Headers(props) {
  const pageDefaultTitle = props.lang === 'en'
    ? 'Muhammad Haris | Full Stack Developer | Creative UI/UX Designer | Photographer'
    : 'Muhammad Haris | Full Stack-utvecklare | Kreativ UI/UX-designer | Fotograf';
    const clinkDefault = 'http://mharis.info';
  const [ptitle, setPtitle] = useState(pageDefaultTitle);
  const [clink, setClink] = useState(clinkDefault);
  useEffect(() => {
    const updatedTitle = props.title
      ? props.title + ' - ' + pageDefaultTitle
      : pageDefaultTitle;
    setPtitle(updatedTitle);
    console.log('Page Title:', updatedTitle);
    const updatedClink = props.clink
      ? clinkDefault + props.clink
      : clinkDefault;
    setClink(updatedClink);
    console.log('Canonical Link:', updatedClink);
  }, [props.title, props.clink, pageDefaultTitle, clinkDefault]);
  return (
    <>
      <HelmetProvider context={{}}>
        <Helmet>
          <title>{ptitle}</title>
          <link rel="canonical" href={clink} />
          <meta property="og:title" content={ptitle}/>
        </Helmet>
      </HelmetProvider>
    </>
  )
}
export default Headers
