import React from 'react';
import Headers from '../components/Headers';

function Home(props) {
  //const link = props.linksDs.find(link => link.link === '/');
  //const pageTitle = props.lang === 'en' ? link.name_en : link.name_se;
  return (
    <>
      <Headers title='' lang={props.lang} clink='' />
      <div>Home - Coming Soon - Upgrading to React</div>
    </>
  )
}

export default Home
