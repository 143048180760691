import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import Headers from '../components/Headers';

function Resume(props) {
  const link = props.linksDs.find(link => link.link === '/resume');
  const pageTitle = props.lang === 'en' ? link.name_en : link.name_se;
  return (
    <>
      <Headers title={pageTitle} lang={props.lang} clink='/resume' />
      <Breadcrumbs title={pageTitle} lang={props.lang} bgimg="https://mhs.mharis.info/wp-content/uploads/2023/04/bg-resume-banner.jpg" />
      <div>Resume</div>
    </>
  )
}

export default Resume
