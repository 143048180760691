import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Breadcrumbs from '../components/Breadcrumbs'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headers from '../components/Headers';

function Volunteer(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://mhs.mharis.info/wp-json/wp/v2/display-volunteer-experience');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const link = props.linksDs.find(link => link.link === '/volunteer');
  const pageTitle = props.lang === 'en' ? link.name_en : link.name_se;
  return (
    <>
      <Headers title={pageTitle} lang={props.lang} clink='/volunteer' />
      <Breadcrumbs title={pageTitle} lang={props.lang} bgimg="https://mhs.mharis.info/wp-content/uploads/2023/04/bg-volunteer-act-banner.jpg" />
      <div>Volunteer</div>
      <div className="uk-container uk-container-xlarge">
        <div className="uk-grid-large uk-child-width-1-2 uk-child-width-1-2@s" data-uk-grid>

        </div>
      </div>
    </>

  )
}

export default Volunteer
