import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { /*ListGroup,*/ Pagination } from 'react-bootstrap';
import Breadcrumbs from '../components/Breadcrumbs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { /*FaRegHandshake,*/ FaRegEnvelope } from "react-icons/fa";
import { RiTimeLine, RiPhoneLine } from "react-icons/ri";
import { PiPlugsConnectedFill } from "react-icons/pi";
import { MdWorkspacesOutline } from "react-icons/md";
import Headers from '../components/Headers';

function Testimonials(props) {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://mhs.mharis.info/wp-json/wp/v2/display-testimonials');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const link = props.linksDs.find(link => link.link === '/testimonials');
  const pageTitle = props.lang === 'en' ? link.name_en : link.name_se;
  return (
    <>
      <Headers title={pageTitle} lang={props.lang} clink='/testimonials' />
      <Breadcrumbs title={pageTitle} lang={props.lang} bgimg="https://mhs.mharis.info/wp-content/uploads/2023/04/bg-testimonial-banner.jpg" />
      <div className="uk-container uk-container-xlarge">
        <div className="uk-grid-large uk-child-width-1-2 uk-child-width-1-2@s" data-uk-grid>
          {currentItems.map((item) => (
            <div key={item.id}>

                <Row className="testimonials">
                  <Col xs={12} md={4} className="testimonials-person grid justify-items-center text-center">
                    <div className="testimoial-person-thumb style_2 my-0">
                      <img width="370" height="370" src={item.personal_photo} alt={item.name} />
                    </div>
                    <div className="testimoial-content my-0">
                      <h3 className="text-base font-bold text-grne my-0">{item.name}</h3>
                      <span className="text-sm">{item.designation}</span>
                      <div className="text-sm">{item.company}</div>
                    </div>
                  </Col>
                  <Col xs={12} md={8}>
                    {item.date ? ( <><strong><RiTimeLine className="inline-block text-grne" /> Date:</strong> {item.date}<br/></> ) : ( <>No Date Available<br /></> ) }
                    <p className="">{item.testimonial}</p>
                    {item.relation ? ( <><strong><PiPlugsConnectedFill className="inline-block text-grne" /> Connection:</strong> {item.relation}<br/></> ) : ( <></> ) }
                    {item.email ? ( <><strong><FaRegEnvelope className="inline-block text-grne" /> Email:</strong> {item.email}<br/></> ) : ( <></> ) }
                    {item.phone ? ( <><strong><RiPhoneLine className="inline-block text-grne" /> Phone:</strong> {item.phone}<br/></> ) : ( <></> ) }
                    {item.company ? ( <><strong><MdWorkspacesOutline className="inline-block text-grne" /> Worked For:</strong> {item.company}<br/></> ) : ( <></> ) }
                    {item.company_alt ? ( <><strong><MdWorkspacesOutline className="inline-block text-grne" /> Worked For:</strong> {item.company_alt}<br/></> ) : ( <></> ) }
                  </Col>
                </Row>


            </div>
          ))}
        </div>
        <br/><br/>
        <Pagination>
          {Array.from({ length: Math.ceil(data.length / itemsPerPage) }).map((_, index) => (
            <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </>

  )
}

export default Testimonials
