import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { FaPhoneAlt, FaEnvelopeOpenText, FaLinkedinIn, FaFacebookF, FaYoutube, FaTwitter } from "react-icons/fa";
import { RiInstagramFill, RiCloseLine, RiBarChartHorizontalLine } from "react-icons/ri";

function Header(props) {
  let [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);
  function pathMatchRoute(route){
    if(route === location.pathname){
      return true;
    }
    return false;
  }
  function checkLang(lang){
    if(lang === props.lang){
      return true;
    }
    return false;
  }
  const toggleMenu = () => {
    setOpen(!open);
  };
  /*const handleLangClick = (lang) => {
    props.setLang(lang);
  };
  onClick={() => handleLangClick(lang.lang)}*/
  return (
    <div className="bg-white border-b shadow-sm sticky top-0 z-50">
      <div className="bg-ldple text-base font-normal font-sans border-b-grne border-b-2 py-1">
        <div className="uk-container uk-container-xlarge">
          <div data-uk-grid="margin:0px">
            <div className="uk-width-1-1 flex justify-between">
              <ul className="flex space-x-4 header-info mb-0 ps-0">
                <li><Link to="tel:+46768867290" target="_blank" className="cursor-pointer react-icons"><FaPhoneAlt className="hover:animate-bounce" /> +(46) 76 886 72 90</Link></li>
                <li><Link to="mailto:hello@mharis.info" target="_blank" className="cursor-pointer react-icons"><FaEnvelopeOpenText className="hover:animate-bounce" /> hello@mharis.info</Link></li>
              </ul>
              <ul className="flex space-x-2 header-info mb-0 ps-0">
                <li><Link to="https://www.linkedin.com/in/haris2780/" alt="LinkedIn" title="LinkedIn" target="_blank" className="cursor-pointer react-icons"><FaLinkedinIn className="hover:animate-bounce" /></Link></li>
                <li><Link to="https://www.facebook.com/muhammad.haris.ali/" alt="Facebook" title="Facebook" target="_blank" className="cursor-pointer react-icons"><FaFacebookF className="hover:animate-bounce" /></Link></li>
                <li><Link to="https://www.instagram.com/m.haris.ali/" alt="Instagram" title="Instagram" target="_blank" className="cursor-pointer react-icons"><RiInstagramFill className="hover:animate-bounce" /></Link></li>
                <li><Link to="#" alt="Youtube" title="Youtube" target="_blank" className="cursor-pointer react-icons"><FaYoutube className="hover:animate-bounce" /></Link></li>
                <li><Link to="https://twitter.com/haris2780/" alt="Twitter" title="Twitter" target="_blank" className="cursor-pointer react-icons"><FaTwitter className="hover:animate-bounce" /></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header className="flex justify-between items-center px-3 pt-3 uk-container uk-container-xlarge mx-auto" data-uk-navbar="true">
        <div className="font-mono font-semibold text-lg align-middle flex cursor-pointer" onClick={()=>navigate("/")}>
          <img src="https://mhs.mharis.info/wp-content/uploads/2022/06/mhs-logo-purple.png" alt="Muhammad Haris" className="h-16" />
        </div>
        {/* linke items */}
        <ul className={`min-[0px]:max-md:hidden lg:flex lg:items-center lg:pb-0 pb-12 absolute lg:static bg-white lg:z-auto z-[-1] left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20' : 'top-[-600px]'}`}>
          {
            props.linksDs.map((link) => (
            <li key={link.name_en} className={`lg:ml-8 lg:my-0 my-7 pb-1 font-semibold hover:border-b-grne border-b-dple border-b-2 ${pathMatchRoute(link.link) && "border-b-grne border-b-2"}`}>
              <Link to={link.link} className={`text-dple no-underline hover:text-grne hover:no-underline duration-500 ${pathMatchRoute(link.link) && "text-grne"}`}>
                {props.lang === 'en' ? link.name_en : props.lang === 'se' ? link.name_se : link.name_en}
                <div className='uk-navbar-subtitle text-xs text-dple hover:text-dple'>
                  {props.lang === 'en' ? link.subname_en : props.lang === 'se' ? link.subname_se : link.subname_en}
                </div>
              </Link>
            </li>))
          }
        </ul>
        <div className="uk-navbar-dropdown lg:hidden w-full">
          <ul className="uk-nav uk-navbar-dropdown-nav lg:hidden">
            {
              props.linksDs.map((link) => (
              <li key={link.name_en} className={`lg:ml-8 lg:my-0 my-7 pb-1 font-semibold hover:border-b-lple border-b-dple border-b-2 ${pathMatchRoute(link.link) && "border-b-lple border-b-2"}`}>
                  <Link to={link.link} className={`text-dple no-underline hover:text-lple hover:no-underline duration-500 ${pathMatchRoute(link.link) && "text-lple"}`}>{link.name_en} <div className='uk-navbar-subtitle text-xs text-dple hover:text-dple'>{link.subname_en}</div></Link>
              </li>))
            }
          </ul>
        </div>
        <ul>
          {
            props.langsDs.map((lang) => (
              <li key={lang.code} onClick={() => props.setLang(lang.code)}><span className={`cursor-pointer fi ${lang.icon} rounded-full w-8 h-8 mx-1 ${checkLang(lang.code) && "hidden"}`} title={lang.title_en} /></li>
            ))
          }
        </ul>
        {/* Menu icon */}
        <div onClick={toggleMenu} className="uk-navbar-toggle right-8 top-16 cursor-pointer lg:hidden w-7 h-7" uk-toggle="target:.uk-navbar-dropdown; cls:uk-navbar-dropdown; animation:uk-animation-slide-left, uk-animation-slide-bottom">
          {
            open ? <RiCloseLine/> : <RiBarChartHorizontalLine />
          }
        </div>
      </header>
    </div>
  )
}

export default Header
